import { appConfig, appHooks, log } from "@app/app";
import { PaymentProviders } from "@app/payment";
import { StripeProvider } from "@app/stripe"; // todo: refactor to payment/components/Providers/StripePaymentProvider.tsx
import { Fragment, PropsWithChildren } from "react";

import { PCIPaymentProvider } from "./providers/PCIPaymentProvider";

export const PaymentProvider = ({ children }: PropsWithChildren) => {
    const { getState } = appHooks.useBookingEngineStateContext();
    const { property } = getState();

    if (!property) {
        return <Fragment>{children}</Fragment>;
    }

    const paymentProvider = property.payment_provider;

    if (!paymentProvider) {
        log.error("Property has no payment provider configured.");
        return <Fragment>{children}</Fragment>;
    }

    if (paymentProvider.client === PaymentProviders.Stripe) {
        return <StripeProvider stripePublishableKey={appConfig.stripePublishableKey}>{children}</StripeProvider>;
    }

    if (paymentProvider.client === PaymentProviders.PCI) {
        return <PCIPaymentProvider>{children}</PCIPaymentProvider>;
    }

    log.error(`Configured payment provider '${paymentProvider.name}' is not supported.`);

    return <Fragment>{children}</Fragment>;
};
