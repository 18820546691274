import { CheckoutSessionProvider } from "@app/checkout";
import { PaymentProvider } from "@app/payment";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormProvider, useForm } from "react-hook-form";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { tw } from "twind";

import { ActiveBookingContextProvider } from "../context/ActiveBookingContextProvider";
import { AppStateContextProvider } from "../context/AppStateContextProvider";
import { IBookingEngineStateContext } from "../context/BookingEngineStateContext";
import { BookingEngineStateProvider } from "../context/BookingEngineStateProvider";
import { ErrorBoundaryProvider } from "../context/ErrorBoundaryProvider";
import { i18next } from "../services";
import { queryClient } from "../services/queryClient";
import { styles } from "../styles";
import { IBookingEngineState } from "../types";
import { ErrorFallback } from "./ErrorFallback";
import { RouterProvider } from "./router/RouterContext";
import { Routes } from "./Routes";
import { ThemeProvider } from "./ThemeProvider";

export function App() {
    const methods = useForm<IBookingEngineState>({ mode: "onSubmit" });
    const ref = React.useRef<IBookingEngineStateContext>();

    return (
        <div className={tw(styles)} id="test" bablic-exclude="">
            <QueryClientProvider client={queryClient}>
                <ThemeProvider defaultTheme="light" storageKey="hc-ibe-theme">
                    <AppStateContextProvider>
                        <I18nextProvider i18n={i18next}>
                            <FormProvider {...methods}>
                                <ActiveBookingContextProvider>
                                    <BookingEngineStateProvider ref={ref}>
                                        <PaymentProvider>
                                            <CheckoutSessionProvider>
                                                <ErrorBoundaryProvider>
                                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                                        <RouterProvider>{Routes}</RouterProvider>
                                                    </ErrorBoundary>
                                                </ErrorBoundaryProvider>
                                            </CheckoutSessionProvider>
                                        </PaymentProvider>
                                    </BookingEngineStateProvider>
                                </ActiveBookingContextProvider>
                            </FormProvider>
                        </I18nextProvider>
                    </AppStateContextProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </div>
    );
}
