import { addMonths, differenceInMonths, format, isBefore, isSameDay, parse, subMonths } from "date-fns";
import { To } from "history";
import React, { useContext, useState } from "react";

import { RouterContext, useRouter } from "./components/router/RouterContext";
import { ActiveBookingContext, IActiveBookingContext } from "./context/ActiveBookingContext";
import { AppStateContext } from "./context/AppStateContext";
import { BookingEngineStateContext, IBookingEngineStateContext } from "./context/BookingEngineStateContext";
import { ErrorBoundaryContext } from "./context/ErrorBoundaryContext";

export const useAppState = () => {
    const context = useContext(AppStateContext);

    if (context === undefined) {
        throw new Error("useAppState must be used within a AppStateContextProvider");
    }

    return context;
};

export const useBookingEngineStateContext = () => {
    const context = useContext<IBookingEngineStateContext>(BookingEngineStateContext);

    if (context === undefined) {
        throw new Error("useBookingEngineStateContext must be used within a BookingEngineStateProvider");
    }

    return context;
};

export const useActiveBookingContext = () => {
    const context = useContext<IActiveBookingContext>(ActiveBookingContext);

    if (context === undefined) {
        throw new Error("useActiveBookingContext must be used within ActiveBookingContextProvider");
    }

    return context;
};

export interface INavigateOptions {
    replaceState?: boolean;
}

export function useCustomNavigate() {
    const { history } = useRouter();

    const navigate = (to: To, state?: any, { replaceState = false }: INavigateOptions = {}) => {
        const { pathname, search } = history.location;
        const newParams = new URLSearchParams(to.toString());
        const params = new URLSearchParams(search);
        const wasChildNavigatable = params.get("c") !== "thank-you";
        const isChildChanged = !!newParams.get("c") && newParams.get("c") !== params.get("c");
        const navigateFn = isChildChanged && wasChildNavigatable ? history.push : history.replace;

        params.set("c", newParams.get("c") || params.get("c") || "search");

        const nextSearch = replaceState ? newParams.toString() : params.toString();

        navigateFn({ pathname, search: nextSearch.toString() });
    };

    return navigate;
}

// Custom hook for tracking navigation history
export function useHistory() {
    const { history } = useRouter();

    // Navigate back in history
    const goBack = () => {
        history.back();
    };

    // Navigate forward in history
    const goForward = () => {
        history.forward();
    };

    // Get the current navigation state
    const getCurrentLocation = () => history.location;

    return {
        goBack,
        goForward,
        getCurrentLocation,
        history,
    };
}

// Custom hook for accessing route data
export function useMatches() {
    const { route } = useContext(RouterContext);

    return route || {};
}

export const useErrorBoundaryContext = () => {
    const context = React.useContext(ErrorBoundaryContext);

    if (context === undefined) {
        throw new Error("useErrorBoundaryContext must be used within a ErrorBoundaryProvider");
    }

    return context;
};

export const CALENDAR_WINDOW_MONTHS = 6;
export const CALENDAR_BUFFER_MONTHS = 2;

export const useGetCalendarSlidingWindow = (activeDate: Date, checkin?: string) => {
    const checkinDate = parse(checkin || "", "yyyy-MM-dd", new Date());
    const [start, setStart] = useState<Date>(new Date());
    const [end, setEnd] = useState<Date>(addMonths(start, CALENDAR_WINDOW_MONTHS));

    // Calculate the new next calendar window
    let startDate =
        differenceInMonths(subMonths(end, CALENDAR_BUFFER_MONTHS), activeDate) >= 0 ? start : subMonths(end, CALENDAR_BUFFER_MONTHS);
    let endDate = addMonths(startDate, CALENDAR_WINDOW_MONTHS);

    if (checkin && isBefore(addMonths(startDate, CALENDAR_WINDOW_MONTHS), checkinDate)) {
        startDate = new Date();
        endDate = addMonths(checkinDate, CALENDAR_BUFFER_MONTHS);
    }

    if (!isSameDay(start, startDate) && !isSameDay(end, endDate)) {
        setStart(startDate);
        setEnd(endDate);
    }

    return { start: format(startDate, "yyyy-MM-dd"), end: format(endDate, "yyyy-MM-dd") };
};
