import { defineConfig } from "@twind/core";
import presetAutoprefix from "@twind/preset-autoprefix";
import presetExt from "@twind/preset-ext";
import presetLineClamp from "@twind/preset-line-clamp";
import presetRadixUi from "@twind/preset-radix-ui";
import presetTailwind from "@twind/preset-tailwind";
import presetTailwindForms from "@twind/preset-tailwind-forms";
import { strict } from "twind";

/**
 * Shadow DOM can still inherit some css styles from parent page like base font size and
 * line height but we can prevent that as well.
 * First we need to use px instead of rem (Tailwind's default unit)
 * for all css properties. Luckily there’s a way to convert
 * unit during build step:
 *
 * @info - https://github.com/tw-in-js/twind/issues/437#issue-1532077112
 */
const presetRemToPx = ({ baseValue = 16 } = {}) => ({
    finalize(rule: any) {
        return {
            ...rule,
            d: rule.d?.replace(/"[^"]+"|'[^']+'|url\([^)]+\)|(-?\d*\.?\d+)rem/g, (match: any, p1: any) => {
                if (p1 === undefined) return match;
                return `${p1 * baseValue}${p1 == 0 ? "" : "px"}`;
            }),
        };
    },
});

export default defineConfig({
    darkMode: "class",
    presets: [
        presetAutoprefix(),
        presetExt(),
        presetLineClamp(),
        presetTailwind(),
        presetRemToPx(),
        presetTailwindForms(),
        presetRadixUi(),
    ],
    // hash: true,
    theme: {
        container: {
            center: true,
            padding: "2rem",
            screens: {
                "2xl": "1400px",
            },
        },
        mode: strict,
        colors: {
            gray: {
                50: "hsla(0, 0%, 98%, 1)",
                100: "hsla(0, 0%, 95%, 1)",
                200: "hsla(0, 0%, 89%, 1)",
                250: "hsla(0, 0%, 78%, 1)",
                300: "hsla(0, 0%, 64%, 1)",
                400: "hsla(0, 0%, 50%, 1)",
                500: "hsla(0, 0%, 32%, 1)",
                600: "hsla(0, 0%, 21%, 1)",
                700: "hsla(0, 0%, 12%, 1)",
                800: "hsla(0, 0%, 9%, 1)",
                900: "hsla(0, 0%, 3%, 1)",
            },
            blue: {
                50: "hsla(209, 100%, 97%, 1)",
                100: "hsla(209, 100%, 92%, 1)",
                200: "hsla(209, 100%, 85%, 1)",
                250: "hsla(209, 100%, 75%, 1)",
                300: "hsla(209, 100%, 66%, 1)",
                400: "hsla(209, 100%, 56%, 1)",
                500: "hsla(209, 100%, 50%, 1)",
                600: "hsla(209, 100%, 44%, 1)",
                700: "hsla(210, 88%, 39%, 1)",
                750: "hsla(210, 85%, 33%, 1)",
                800: "hsla(212, 81%, 26%, 1)",
                900: "hsla(213, 77%, 21%, 1)",
                950: "hsla(213, 83%, 15%, 1)",
            },
            red: {
                100: "hsla(351, 100%, 78%, 1)",
                200: "hsla(343, 85%, 50%, 1)",
                300: "hsla(343, 86%, 41%, 1)",
            },
            green: {
                50: "hsla(120, 60%, 98%, 1)",
                100: "hsla(113, 67%, 78%, 1)",
                200: "hsla(131, 67%, 42%, 1)",
                300: "hsla(131, 57%, 38%, 1)",
            },
            yellow: {
                100: "hsla(36, 100%, 78%, 1)",
                200: "hsla(36, 100%, 50%, 1)",
                300: "hsla(39, 86%, 47%, 1)",
            },
            white: "#ffffff",
            black: "hsl(0, 100%, 0%)",
        },
        extend: {
            colors: {
                primary: {
                    DEFAULT: "hsl(var(--hc-primary))",
                    foreground: "hsl(var(--hc-primary-foreground))",
                },
                secondary: {
                    DEFAULT: "hsl(var(--hc-secondary))",
                    foreground: "hsl(var(--hc-secondary-foreground))",
                },
            },
            screens: {
                tall: { raw: "(min-height: 650px)" },
            },
            keyframes: {
                wiggle: {
                    "0%, 100%": { transform: "rotate(-3deg)" },
                    "50%": { transform: "rotate(3deg)" },
                },
                flashy: {
                    "0%, 57.14%": {
                        animationTimingFunction: "cubic-bezier(0.33, .66, .66, 1)",
                        transform: "translate(0)",
                    },

                    "28.57%": {
                        animationTimingFunction: "cubic-bezier(0.33, 0, .66, .33)",
                        transform: "translateY(-6px)",
                    },

                    "100%": {
                        transform: "translate(0)",
                    },
                },
            },
            animation: {
                wiggle: "wiggle 1s ease-in-out infinite",
                flashy: "flashy 1.05s infinite", // important1 1.05s infinite
            },
        },
    },
    preflight: {
        // "@font-face": [
        //     {
        //         // fontFamily: '"AmerigoBT-MediumA"',
        //         fontFamily: "Roboto",
        //         fontWeight: "700",
        //         // src: 'url(https://www.aethos.com/wp-content/themes/Aethos/dist/fonts/Amerigo-font.woff2) format("woff")',
        //         src: 'url("/public/fonts/robotov30latinregular.woff2") format("woff2")',
        //     },
        // ],
    },
});
