import { appHooks, appTypes, appUtils, log, storageManager } from "@app/app";
import { AvailabilityRequestType } from "@app/availability";
import { bookingHooks } from "@app/booking";
import { PaymentIntentCaptureMethod, PaymentTypes, paymentUtils } from "@app/payment";
import { UtilsIdentifier, UtilsUrl } from "@hotelchamp/common";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { CheckoutSessionDefaults } from "../constants/CheckoutSessionDefaults";
import { CheckoutSessionProviderMode } from "../constants/CheckoutSessionProviderMode";
import { CheckoutSessionStatus } from "../constants/CheckoutSessionStatus";
import { StorageKeys } from "../constants/StorageKeys";
import {
    CheckoutSessionContext,
    ICheckoutSessionContext,
    ICheckoutSessionProviderStartSessionProps,
} from "../context/CheckoutSessionContext";
import { useUpsertCheckoutSession } from "../hooks";
import { ICheckoutSessionState } from "../types";

export type TCheckoutSessionProviderChildrenRenderProp = (context: ICheckoutSessionContext) => React.ReactNode | React.ReactNode[];

export interface ICheckoutSessionProviderProps {
    children?: React.ReactNode | React.ReactNode[] | TCheckoutSessionProviderChildrenRenderProp;
    mode?: CheckoutSessionProviderMode;
}

const DEFAULT_CAPTURE_METHOD = PaymentIntentCaptureMethod.AutomaticAsync;

const createEmptyState = (): ICheckoutSessionState => ({
    session_id: UtilsIdentifier.uuid(),
    status: CheckoutSessionStatus.InProgress,
    capture_method: DEFAULT_CAPTURE_METHOD,
    payment_type: PaymentTypes.Direct,
    has_last_payment_error_been_shown: false,
    expired_at: Date.now() + CheckoutSessionDefaults.SessionTtl,
});

/**
 * @note - When debugging session id's and id changes more often, possible reasons:
 *  - The state is initialized with empty state and a new session id (before restored from localStorage)
 *  - The state will asynchronousely restored from localStorage, if present, which could update initial session id
 *  - After state is initialized and restored, change in the cart could cause a change of capture_method which results in
 *      creating a new checkout session and thus a new session id
 * @returns
 */
export function CheckoutSessionProvider({ mode = CheckoutSessionProviderMode.Checkout, children }: ICheckoutSessionProviderProps) {
    const [state, setState] = useState<ICheckoutSessionState>(createEmptyState());
    const { setValue: setBookingEngineState } = useFormContext<appTypes.IBookingEngineState>();
    const [isCheckoutSessionInitializing, setIsCheckoutSessionInitializing] = useState(true);
    const [activeAllowedPaymentTypes, setActiveAllowedPaymentTypes] = useState<PaymentTypes[]>([]);
    const {
        getAvailabilityParams,
        cart,
        property,
        initialized: isBookingEngineStateInitialized,
        isCartInitialized,
        resetBooking,
    } = appHooks.useBookingEngineStateContext();
    const [activePaymentType, setActivePaymentType] = useState<PaymentTypes>(state.payment_type);
    const propertyId = appUtils.resolvePropertyIdOrFail();
    const availabilitySearchQuery = getAvailabilityParams(AvailabilityRequestType.Check);
    const upsertCheckoutSessionMutator = useUpsertCheckoutSession(propertyId!, availabilitySearchQuery);
    const isDirectActivePaymentType = activePaymentType === PaymentTypes.Direct;
    const isOffSessionActivePaymentType = activePaymentType === PaymentTypes.OffSession;
    const hasActiveCheckoutSession =
        (isDirectActivePaymentType && !!state.external_payment_intent_id && !!state.external_payment_intent_secret) ||
        (isOffSessionActivePaymentType && !!state.external_setup_intent_id && !!state.external_setup_intent_secret);
    const areCartItemsRefundable = !!cart?.refundable && !!cart?.cancellation_within_window;
    const bookingId = state.booking_id;
    const { data: reservedBooking } = bookingHooks.useGetReservedBooking(propertyId, bookingId || "", { enabled: !!bookingId });
    const paymentIntent = reservedBooking?.payment_intent;
    const paymentSetupIntent = reservedBooking?.payment_setup_intent;

    // set state in storage on change
    // normally setCheckoutSessionState should be used over setState because it will set it in localStorage,
    // but this is to make sure it won't get out of sync, with the cost of the additional overhead
    useEffect(() => {
        if (!isCheckoutSessionInitializing) {
            storageManager.getGlobalSession().set(StorageKeys.CheckoutSessionState, state);
        }
    }, [state, isCheckoutSessionInitializing]);

    // restore state from storage initially when component is mounted
    useEffect(() => {
        if (isBookingEngineStateInitialized && isCartInitialized) {
            storageManager
                .getGlobalSession()
                .get<ICheckoutSessionState>(StorageKeys.CheckoutSessionState)
                .then((storageState) => {
                    const hasValidExternalIntentState =
                        (!!storageState?.external_payment_intent_id && !!storageState.external_payment_intent_secret) ||
                        (!!storageState?.external_setup_intent_id && !!storageState.external_setup_intent_secret);
                    const hasValidStateInStorage = !!storageState && hasValidExternalIntentState && !!storageState.session_id;

                    // For now force capture method to be AutomaticAsync because pay later is implemented with setup intent
                    // manual capturing is not needed
                    // const captureMethod = areCartItemsRefundable ? PaymentIntentCaptureMethod.Manual : DEFAULT_CAPTURE_METHOD;
                    const captureMethod = DEFAULT_CAPTURE_METHOD;

                    if (hasValidStateInStorage) {
                        // Restore valid session state from localStorage

                        setActivePaymentType(storageState.payment_type);

                        setState(storageState);

                        setIsCheckoutSessionInitializing(false);
                    } else if (mode === CheckoutSessionProviderMode.Checkout) {
                        // Create new session

                        const nextState = {
                            ...createEmptyState(),
                            capture_method: captureMethod,
                        };

                        setState(nextState);

                        setIsCheckoutSessionInitializing(false);
                    }
                });
        }
    }, [isBookingEngineStateInitialized, isCartInitialized, setActivePaymentType]);

    useEffect(() => {
        // const hasActiveAllowedPaymentTypes = !!activeAllowedPaymentTypes.length;

        if (property?.allowed_payment_types && Array.isArray(property?.allowed_payment_types)) {
            const nextActiveAllowedPaymentTypes = property?.allowed_payment_types || [];
            const isActivePaymentTypeAllowed = nextActiveAllowedPaymentTypes.includes(state.payment_type);

            setActiveAllowedPaymentTypes(property.allowed_payment_types);

            if (nextActiveAllowedPaymentTypes.length && !isActivePaymentTypeAllowed) {
                // clear checkout session when allowed payment types has been changed during a checkout session

                clearAllState({ payment_type: nextActiveAllowedPaymentTypes[0] }).then(() => {
                    alert(
                        "It seems that the allowed payment types have been changed during the checkout session. The state will be cleared and the page will be reloaded"
                    );

                    document.location.reload();
                });
            }
        }
    }, [property?.allowed_payment_types, state.payment_type]);

    const upsertCheckoutSession = useCallback(async (): Promise<ICheckoutSessionState> => {
        if (propertyId) {
            const nextCheckoutSessionState = await upsertCheckoutSessionMutator.mutateAsync(state);

            if (nextCheckoutSessionState.session_id) {
                setState(nextCheckoutSessionState);

                return nextCheckoutSessionState;
            }

            return nextCheckoutSessionState;
        } else {
            throw new Error("No property id set");
        }
    }, [upsertCheckoutSessionMutator, propertyId, availabilitySearchQuery]);

    const clearCheckoutSession = useCallback(async (forcedState?: Partial<ICheckoutSessionState>): Promise<void> => {
        await storageManager.getGlobalSession().clear(StorageKeys.CheckoutSessionState);

        const nextState = { ...createEmptyState(), ...(forcedState || {}) };

        setState(nextState);
    }, []);

    const clearAllState = async (forcedState?: Partial<ICheckoutSessionState>) => {
        clearCheckoutSession(forcedState);

        await Promise.all(storageManager.getAll().map((storage) => storage.clearAll())).then(() => {
            console.warn("All storages have been cleared");
        });

        resetBooking();

        setTimeout(() => {
            const nextUrl = UtilsUrl.setUrlParams(document.location.href, { p: "base", c: "search" });

            document.location.href = nextUrl;
        });
    };

    (window as any).clearAllState = clearAllState;

    const setCheckoutSessionState = useCallback(
        async (nextStateProps: Partial<ICheckoutSessionState>) => {
            const nextState = {
                ...state,
                ...nextStateProps,
            };

            setState(nextState);

            await storageManager.getGlobalSession().set(StorageKeys.CheckoutSessionState, nextState);
        },
        [setState, state]
    );

    // Should be called in checkout step when cart has items
    // upsert session server side which will create a new payment intent for payment provider
    // @param guest     to force guest params from the outside to compare against
    const startSession = useCallback(
        async ({ payment_type, forceNew, forceReservedBooking = {} }: ICheckoutSessionProviderStartSessionProps = {}) => {
            if (isCheckoutSessionInitializing) {
                throw Error("Cannot start session while state is initializing");
            }

            const currentReservedBooking = forceReservedBooking || reservedBooking;

            // const nextCaptureMethod = areCartItemsRefundable ? PaymentIntentCaptureMethod.Manual : DEFAULT_CAPTURE_METHOD;
            const nextCaptureMethod = DEFAULT_CAPTURE_METHOD;
            const leadingPaymentType = payment_type || activePaymentType;
            // const isCaptureMethodChanged = nextCaptureMethod !== state.capture_method;
            const isCaptureMethodChanged = false;
            const isPaymentTypeChanged = leadingPaymentType !== state.payment_type;
            const isStatusFinished = state.status === CheckoutSessionStatus.Finished;
            const currentEmail = currentReservedBooking?.guest?.email;
            const isEmailChanged = !!state.guest?.email && state.guest?.email !== currentEmail;

            const paymentType = currentReservedBooking?.payment_type;
            const isDirectPaymentType = paymentType === PaymentTypes.Direct;
            const paymentIntentModel = isDirectPaymentType
                ? currentReservedBooking?.payment_intent
                : currentReservedBooking?.payment_setup_intent;

            const hasSessionFinishedPayment = !!paymentIntentModel?.status && paymentUtils.isPaymentAccepted(paymentIntentModel.status);
            let nextState = {
                ...state,
            };

            if (
                (isCartInitialized && isCaptureMethodChanged) ||
                hasSessionFinishedPayment ||
                isStatusFinished ||
                isPaymentTypeChanged ||
                isEmailChanged ||
                forceNew
            ) {
                nextState = {
                    ...createEmptyState(),
                    capture_method: nextCaptureMethod,
                    payment_type: leadingPaymentType,
                };

                setState(nextState);
            }

            if (!upsertCheckoutSessionMutator.isLoading) {
                // Only upsert the checkout session when we're not already
                // upserting it, else we'll get stuck in an infinite loop,
                // resulting in the API throttling us.

                await upsertCheckoutSessionMutator.mutateAsync(nextState).then((nextCheckoutSessionState) => {
                    if (nextCheckoutSessionState.session_id) {
                        const nextStateUpdatedOnServer = {
                            ...nextState,
                            ...nextCheckoutSessionState,
                        };

                        // @TODO - availability_request_data is send as query string to the server.
                        // The value in the CheckoutSessionData is not used other then for updating Stripe when
                        // a value is assinged to it. The actual value doesn't matter.
                        // This should be refactored to rate id and room id.
                        // Since availability_request_data in the checkout session response is not used client side,
                        // it is removed from state to avoid confusion.
                        if ("availability_request_data" in nextStateUpdatedOnServer) {
                            delete nextStateUpdatedOnServer["availability_request_data"];
                        }

                        setState(nextStateUpdatedOnServer);
                    }
                });
            }
        },
        [
            isCheckoutSessionInitializing,
            activePaymentType,
            state,
            reservedBooking?.payment_type,
            reservedBooking?.payment_intent,
            reservedBooking?.payment_setup_intent,
            isCartInitialized,
            upsertCheckoutSessionMutator,
            availabilitySearchQuery,
        ]
    );

    useEffect(() => {
        log.debug("hiero1.5.6 - CheckoutSessionProvider::middle - CHANGED - checkoutSessionState", state);
    }, [state]);

    log.debug("hiero1.5.7 - CheckoutSessionProvider::middle - re-render - checkoutSessionState", state);

    const updateSessionExpiredAt = useCallback(() => {
        setState({ ...state, expired_at: Date.now() + CheckoutSessionDefaults.SessionTtl });
    }, [state]);

    useEffect(() => {
        if (!isCheckoutSessionInitializing && !!state.payment_type && state.payment_type !== activePaymentType) {
            // upsertCheckoutSession(state);
            setActivePaymentType(state.payment_type);

            // payment type has change, a new session should be started
            setTimeout(() => startSession({ payment_type: state.payment_type, forceNew: true }), 100);
        }
    }, [state.payment_type, isCheckoutSessionInitializing, activePaymentType, startSession]);

    const value: ICheckoutSessionContext = React.useMemo(
        () => ({
            state,
            startSession,
            isLoading: upsertCheckoutSessionMutator.isLoading,
            upsertCheckoutSession,
            clearCheckoutSession,
            hasActiveCheckoutSession,
            isCheckoutSessionInitializing,
            booking: reservedBooking,
            paymentIntent,
            paymentSetupIntent,
            setState: setCheckoutSessionState,
            updateSessionExpiredAt,
        }),
        [
            state,
            startSession,
            upsertCheckoutSessionMutator.isLoading,
            hasActiveCheckoutSession,
            isCheckoutSessionInitializing,
            clearCheckoutSession,
            upsertCheckoutSession,
            reservedBooking,
            paymentIntent,
            paymentSetupIntent,
            setCheckoutSessionState,
            updateSessionExpiredAt,
        ]
    );

    return (
        <CheckoutSessionContext.Provider value={value}>
            {typeof children === "function" ? children(value) : children}
        </CheckoutSessionContext.Provider>
    );
}
