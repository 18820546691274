import { availabilityTypes } from "@app/availability";
import classNames from "classnames";
import {
    addDays,
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    isToday,
    setHours,
    startOfMonth,
    startOfWeek,
} from "date-fns";
import { twMerge } from "tailwind-merge";

import { PriceableItemPrice } from "../PriceableItemPrice";
import { SelectableDateTypes } from "./constants/SelectableDateTypes";

export enum SelectableDates {
    Future = "future",
    Past = "past",
    Both = "both",
}

interface ICalendarSelectionProps {
    currentDate: Date;
    startSelectedDate?: Date;
    endSelectedDate?: Date;
    onChange: any;
    isFutureDateDisabled?: boolean;
    isRangeSelection?: boolean;
    selectableDates: SelectableDateTypes;
    availability?: availabilityTypes.ICalendarAvailability;
    currency?: string;
}

const largeFormatCurrencies = ["EGP", "HKD", "IDR", "INR", "ISK", "JPY", "KRW", "MXN", "RUB", "THB", "TWD", "TRY", "UAH", "ZAR"];

export const CalendarSelection = ({
    currentDate,
    startSelectedDate,
    endSelectedDate,
    onChange,
    isRangeSelection = false,
    selectableDates,
    availability,
    currency = "EUR",
}: ICalendarSelectionProps) => {
    const endOfSelectedMonth = endOfMonth(currentDate);
    const endDate = endOfWeek(endOfSelectedMonth, { weekStartsOn: 1 });
    const days: any[] = [];
    let startDate = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
    const today = new Date();
    const todayEndBookableTime = setHours(today, 18);
    const hasRates = Object.values(availability || {}).every((entry) => typeof entry === "number");
    const isDisabled = (day: any) =>
        !availability?.[format(day.date, "yyyy-MM-dd")] ||
        (selectableDates === SelectableDateTypes.Past && isAfter(day.date, new Date())) ||
        (selectableDates === SelectableDateTypes.Future &&
            isToday(today) &&
            !isBefore(today, todayEndBookableTime) &&
            isBefore(day.date, new Date()));

    const isFistDisabledDayAvailableForCheckout = (day: Date): boolean => {
        const selectedDay = endSelectedDate || startSelectedDate || day;
        let nextDate = addDays(selectedDay, 1);

        if (!startSelectedDate || (endSelectedDate && !isSameDay(endSelectedDate, day))) {
            return false;
        }

        while (!isDisabled({ date: nextDate })) {
            nextDate = addDays(nextDate, 1);
        }

        return isSameDay(nextDate, day);
    };

    const checkDateBetween = (current: Date, first?: Date, second?: Date) => {
        let selected = false;

        if (first) {
            selected = first && isSameDay(first, current);
        }

        if (!selected && second) {
            selected = second && isSameDay(second, current);
        }

        if (!selected && first && second) {
            selected = isBefore(first, current) && isAfter(second, current);
        }

        return selected;
    };

    const handleSelectDate = (date: Date) => {
        // Reset previous selection if any
        if (startSelectedDate && endSelectedDate) {
            onChange({ startSelectedDate: date, endSelectedDate: null });
        }

        if (isRangeSelection && startSelectedDate && !endSelectedDate && isAfter(date, startSelectedDate)) {
            // Check if all dates inbetween are available:
            let availableSelection = true;
            const betweenDates = eachDayOfInterval({ start: startSelectedDate, end: date });

            for (const d of betweenDates) {
                if (isFistDisabledDayAvailableForCheckout(d) ? false : !availability?.[format(d, "yyyy-MM-dd")]) {
                    availableSelection = false;
                }
            }

            if (availableSelection) {
                onChange({ startSelectedDate, endSelectedDate: date });
            } else {
                onChange({ startSelectedDate: date, endSelectedDate: null });
            }
        } else {
            onChange({ startSelectedDate: date, endSelectedDate: null });
        }
    };

    const showRates = (day: any) => (endSelectedDate ? hasRates && day.rate && !isSameDay(day.date, endSelectedDate) : hasRates);

    while (startDate <= endDate) {
        days.push({
            date: startDate,
            isCurrentMonth: isSameMonth(startDate, currentDate),
            isSelected: checkDateBetween(startDate, startSelectedDate, endSelectedDate),
            isStartSelected: startSelectedDate && isSameDay(startDate, startSelectedDate),
            isEndSelected: endSelectedDate && isSameDay(startDate, endSelectedDate),
            isToday: isSameDay(startDate, new Date()),
            isSelectable: !!availability?.[format(startDate, "yyyy-MM-dd")],
            rate: availability?.[format(startDate, "yyyy-MM-dd")] ? Number(availability?.[format(startDate, "yyyy-MM-dd")]) : null,
        });

        startDate = addDays(startDate, 1);
    }

    const currencyFormat: Intl.NumberFormatOptions = {
        currency,
        currencyDisplay: "narrowSymbol",
        notation: "compact",
        compactDisplay: "long",
    };

    if (largeFormatCurrencies.indexOf(currency) !== -1) {
        currencyFormat.maximumSignificantDigits = 3;
    }

    return (
        <div className="w-full pb-3">
            <div className="grid grid-cols-7 text-xs leading-6 text-gray-300 my-2">
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
                <div>S</div>
            </div>
            <div className="grid grid-cols-7 rounded-lg text-xs gap-y-3">
                {days.map((day, dayIdx) =>
                    !isSameMonth(day.date, currentDate) ? (
                        <div key={dayIdx} />
                    ) : (
                        <button
                            data-testid={`calendar-day-${format(day.date, "dd-MM-yyyy")}`}
                            key={format(day.date, "dd-MM-yyyy")}
                            type="button"
                            onClick={() => handleSelectDate(day.date)}
                            disabled={isFistDisabledDayAvailableForCheckout(day.date) ? false : isDisabled(day)}
                            className={twMerge(
                                "focus:z-1 font-medium group",
                                day.isCurrentMonth &&
                                    (day.isSelectable || isFistDisabledDayAvailableForCheckout(day.date)) &&
                                    !day.isToday &&
                                    !day.isSelected &&
                                    "hover:rounded-lg hover:bg-primary hover:text-white",
                                !day.isSelected && "white",
                                !day.isSelected && day.isCurrentMonth && !day.isToday && day.isSelectable && "bg-transparant text-gray-900",
                                !day.isSelected &&
                                    !day.isSelectable &&
                                    !day.isToday &&
                                    !isFistDisabledDayAvailableForCheckout(day.date) &&
                                    "text-gray-300 cursor-not-allowed",
                                day.isSelected && !!endSelectedDate && "bg-secondary text-primary",
                                day.isStartSelected && "rounded-l-lg",
                                day.isEndSelected && "rounded-r-lg"
                            )}
                        >
                            <time
                                dateTime={format(day.date, "dd-MM-yyyy")}
                                className={classNames(
                                    "flex h-full w-full items-center justify-center",
                                    (day.isStartSelected || day.isEndSelected) && "rounded-lg bg-primary text-primary-foreground",
                                    day.isToday && "rounded-lg bg-gray-100 hover:bg-primary text-gray-800"
                                )}
                            >
                                <div
                                    className={classNames("h-11 w-14 justify-between flex flex-col items-center relative py-2 ", {
                                        "justify-between": hasRates,
                                        "justify-center": !hasRates,
                                        "group-hover:text-white": day.isSelectable && !day.isSelected,
                                        "h-14": hasRates,
                                        "text-gray-300":
                                            (selectableDates === SelectableDateTypes.Past && isAfter(day.date, new Date())) ||
                                            (selectableDates === SelectableDateTypes.Future &&
                                                isBefore(day.date, new Date()) &&
                                                day.isSeleced),
                                    })}
                                >
                                    <div className="flex items-center justify-center">
                                        {isFistDisabledDayAvailableForCheckout(day.date) ||
                                        (endSelectedDate && isSameDay(day.date, endSelectedDate)) ? null : !day.isSelectable ||
                                          !day.rate ? (
                                            <div className="border-t border-b border-gray-300 border-b-white w-4 h-0 absolute" />
                                        ) : null}

                                        <span className={twMerge("z-10", hasRates && "font-medium text-sm")}>{format(day.date, "d")}</span>
                                    </div>
                                    {showRates(day) ? (
                                        <PriceableItemPrice
                                            item={day.rate || 0}
                                            className="justify-center"
                                            moneyClassName={twMerge(
                                                "whitespace-nowrap text-xs font-normal text-[#A3A3A3]",
                                                day.isSelected && "text-color-secondary",
                                                !day.isSelected && "group-hover:text-white",
                                                (day.isStartSelected || day.isEndSelected) && "text-color-secondary"
                                            )}
                                            formatOptions={currencyFormat}
                                        />
                                    ) : null}
                                </div>
                            </time>
                        </button>
                    )
                )}
            </div>
        </div>
    );
};
