import { useTheme } from "@app/app/components/ThemeProvider";
import { cn, Title } from "@ui";
import React from "react";

import { ITitleProps } from "./Title";

export interface IThemeTitleProps extends ITitleProps {}

export const ThemeTitle: React.FC<IThemeTitleProps> = React.forwardRef<HTMLHeadingElement, IThemeTitleProps>(
    ({ children, className, ...titleProps }, ref) => {
        const { theme } = useTheme();
        const size = titleProps.size || "xxl";
        const themeOverrides = theme.overrides?.title[size];

        return (
            <Title {...titleProps} className={cn(themeOverrides?.className, className)}>
                {children}
            </Title>
        );
    }
);

ThemeTitle.displayName = "ThemeTitle";
// font-[AmerigoBT-MediumA]
