import { appHooks, tracker, useRouter } from "@app/app";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Alert, Flex, Text } from "@ui";
import { Fragment, useEffect } from "react";

import { SearchConfigurator } from "../components/SearchConfigurator";

export function SearchItemPage() {
    const navigate = appHooks.useCustomNavigate();
    const { getState } = appHooks.useBookingEngineStateContext();
    const lang = getState("language");
    const convertConfiguration = getState("property.convert_configuration");
    const { data } = useRouter();

    const handleSearchClick = () => {
        navigate(data.next);
    };

    useEffect(() => {
        tracker.searchPage();
    }, []);

    return (
        <Fragment>
            <SearchConfigurator onSearchClick={handleSearchClick}>
                {convertConfiguration &&
                convertConfiguration.is_direct_callout_enabled &&
                convertConfiguration.direct_callout &&
                convertConfiguration.direct_callout[lang] ? (
                    <span className="hidden md:inline">
                        <Alert className="mb-7">
                            <Flex alignItems="start" justifyContent="start" className="gap-x-2">
                                <CheckCircleIcon width={20} className="text-primary" />
                                <Text className="text-gray-500 text-sm">{convertConfiguration.direct_callout[lang]}</Text>
                            </Flex>
                        </Alert>
                        <div className="border-t mb-7" />
                    </span>
                ) : (
                    <Fragment />
                )}
            </SearchConfigurator>
        </Fragment>
    );
}
